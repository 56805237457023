import React from "react";
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
import FormResponseModel from "../components/form-response-model";
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
import globe from "../globals"
import PopupModel from "../components/popup-model";


export default function Contactus({ pageContext }) {
    const { contactus } = pageContext;

    let yoast_head = globe.domainReplace(contactus.yoast_head)
    let temp = yoast_head.match(/<script.+?<\/script>/g)[0] || ''
    let schema = temp !== undefined && temp !== null && temp.length > 70 ? temp.substring(62, temp.length-9) : {}
    let des = contactus.excerpt.substr(3, contactus.excerpt.length - 8) || '';

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>{contactus.title}</title>

                {contactus.yoast_head.match(/name="description"/g) === null ? <meta name="description" content={des}/> : null}

                { ReactHtmlParser(yoast_head) }

                <script type="application/ld+json">
                    {schema}
                </script>

            </Helmet>

            <Header/>
            <Menu/>

            <div className="section-title pt-5">
                <h1 className="section-title-heading fsm-theme-heading">{contactus.title}</h1>
            </div>


            <div className="container-fluid pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="fsm-theme-text fsm-box-shadow">
                                <ContactForm modalId={''}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer/>
            <FormResponseModel />
            <PopupModel name={"formModal"}/>
        </>
    )
}
